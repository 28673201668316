<template>
  <BookingPosition
    displayedCard="rejected"
  >
    <BookingTechnicianRejectedCard />
  </BookingPosition>
</template>

<script>
import BookingPosition from '@/views/BookingPosition.vue'
import BookingTechnicianRejectedCard from '@/components/BookingPosition/BookingTechnicianRejectedCard.vue'

export default {
  name: 'BookingPositionRejected',
  components: {
    BookingPosition,
    BookingTechnicianRejectedCard
  }
}
</script>

<style lang="less">
</style>
